import React from "react"
import { Helmet } from "react-helmet"

const App = () => {
  return (
    <div>
      <Helmet>
        <meta
          http-equiv="refresh"
          content="0;url=https://play.google.com/store/apps/details?id=com.solv.commerce"
        />
      </Helmet>
    </div>
  )
}

export default App
